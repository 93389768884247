module.exports = {
    "landing_page__title": "AI Avatars",
    "landing_page__title_v2": "AI Avatars",
    "landing_page__title_tooncoin": "Get your exclusive AI Avatars now!",
    "landing_page__text_1": "What are your avatars like? Time to meet them",
    "landing_page__text_2": "Let our AI blow your imagination with a great number of incredible neural avatars generated right from your photos.",
    "landing_page__text_v2": "Fantastic. Unreal. Mind-blowing.<br>The future is here. Get dozens of incredible neural avatars from your photos.",
    "landing_page__text_tooncoin": "Two free avatar pack is available to any user with non-empty Ethereum wallet.",

    "disclaimer_modal__title": "Disclaimer",
    "disclaimer_modal__text": "Please note that our AI is as almighty as it is unpredictable. This means that the generated results however stunning may contain artefacts, inaccuracies, and scenes one may consider improper/provocative/vivid.",
    "disclaimer_modal__button": "Continue",

    "different_people_modal__unmatched_title": "Are these the <span>same</span> person?",
    "different_people_modal__unmatched_text": "Your photos seem to depict <span class=\"text-bold\">different</span> people, while the person being the <span class=\"text-bold\"><span class=\"text-color\">same</span></span> is one of the key requirements. <span class=\"text-bold\">Please make sure we are not mistaken.</span>",
    "different_people_modal__multiple_faces_text": "These photos have multiple people on them, please choose photos with one person only.",
    "different_people_modal__button_different_people_cancel": "Re-check",
    "different_people_modal__button_different_people_confirm": "No, I’m good to go",

    "create_page__title": "Upload your photos",
    "create_page__rule_1": "<span>At least 10 photos</span>",
    "create_page__rule_2": "All the photos are of <span>the same person</span>",
    "create_page__rule_3": "<span>Only one person</span> in a photo",
    "create_page__rule_4": "<span>Selfies</span> and <span>portraits</span>",
    "create_page__rule_5": "Different <span>backgrounds</span>",
    "create_page__rule_6": "Different <span>face expressions</span>",
    "create_page__rule_7": "Photos are taken from <span>different angles</span>",
    "create_page__rule_8": "No photoshoots (settings should differ)",
    "create_page__rule_9": "No group portraits",
    "create_page__rule_10": "No nudes",
    "create_page__rule_11": "No kids",
    "create_page__photos_count": "Uploaded: <span>{{num}} photos</span>",
    "create_page__photos_error": "(Some photos cannot be used. Tap to learn more)",
    "create_page__delete_photo": "Select a photo to delete",

    "sample_pack__title": "Sample pack",
    "sample_pack__text": "We’ve taken a well-known person to generate a demo pack for you to know what to expect. Stunning, huh? <span>It’s your turn now!</span>",
    "sample_pack__originals_text": "Originals",
    "sample_pack__results_text": "AI Magic",

    "pack_page__title": "Results",

    "packs_page__title": "My avatars",

    "button__subscribe_with_price": "subscribe by {{price}}",
    "button__go": "go!",
    "button__ok": "ok",
    "button__select_photos": "select photos",
    "button__select_more": "select more",
    "button__add_more": "Add more",
    "button__proceed": "proceed",
    "button__select_to_delete": "Select a photo to delete",
    "button__tap_to_delete": "Tap a photo to delete",
    "button__cancel": "cancel",
    "button__retry": "retry",
    "button__try_again": "try again",
    "button__delete": "delete",
    "button__download_all": "Download all",
    "button__download_avatar": "download avatar",
    "button_more": "Go to feed for more",
    "button__go_to_sample_pack": "go to sample pack",
    "button__show_me_the_sample": "show me the sample",
    "btn_create_avatars": "Create your avatars",
    "button_subscribe": "Start 3-day free trial*",
    "button_subscribe_with_price": "Subscribe for {{price}}",
    "button_subscribe_sub": "*then {{price}}/week",
    "button_proceed_subscribe_with_price": "Proceed for {{price}}",
    "button_proceed_subscribe": "Proceed with 3-day free trial*",
    "button_continue": "Continue",

    "processing_title": "Processing",
    "processing_text_1": "The AI is now using your photos for training. <span>It is gonna take some time</span>, so please keep an eye on the countdown above.",
    "processing_text_2": "Here are some <span>cool effects</span> to play with from other sections of our app to <span>brighten</span> your waiting.",
    "processing_expired_text": "We need some more time",

    "latest_packs_title": "Your previous avatars",

    "limit_reached_message": "Your weekly limit is reached, Please wait until another two packs get unlocked.",
    "limit_reached_message_no_inapp": "Your weekly limit is achieved. You should wait until another two packs get unlocked with your subscription.",
    "limit_reached_button_purchase": "Close",
    "limit_reached_button_cancel": "Cancel",
    "limit_reached_button_ok": "OK",

    "subscribe_modal_title": "AI Avatar Wizard",
    "subscribe_modal_message": "This is a PRO functionality. Two AI Avatar packs will be available weekly as a part of subscription with an option of one-time purchase at a discount.",
    // "subscribe_modal_message": "This is a PRO functionality. Two AI Avatar packs will be available weekly as a part of subscription.",
    "subscribe_message": "Enjoy two Avatar Packs weekly with an option of one-time purchase at a discount price. Only {{price}}/week.",
    "subscribe_modal_button_go_pro": "GO PRO",
    "subscribe_modal_button_not_now": "Not now",

    "update_app_modal_message": "Please update the app to continue",
    "update_app_modal_button_confirm": "update",
    "update_app_modal_button_cancel": "not now",

    "footer_pro_info": "Renews automatically. Cancel Anytime. No commitment.",

    "watermark_screen__title": "",
    "draggable_watermark_tooltip": "<span>Tap&Drag</span> to move <br>& delete watermark",
    "watermark_removal_modal__title": "Yep, you can remove the watermark, but…",
    "watermark_removal_modal__text1": "We’d like you to keep it and let your friends know about our app. Don’t feel like keeping it? Spread the hash-word about {{app_name}}.",
    "watermark_removal_modal__text2": "The entire amount of money raised goes to a charity fund!",
    "watermark_removal_modal__notice": "<span class=\"text-bold\">TIP:</span> you can just drag the watermark to the position you want.",
    "watermark_removal_modal__choice": "Your choice?",
    "watermark_removal_modal__confirm": "Remove watermark",
    "watermark_removal_modal__cancel": "Spread the good!",
    "watermark_removal_modal__close": "Close",

    "pack_more_images_free_title": "Wanna see <span>«{{num}}»</span> more avatars?",
    "pack_more_images_free_text": "Go <span>PRO</span> now and enjoy all the premium privileges.",
    "pack_more_images_free_button": "GO PRO",
    "pack_more_images_trial_title": "We’ve got <span>«{{num}}»</span> more avatars for you!",
    "pack_more_images_trial_text": "You can see them right now by finishing your trial and going <span>PRO</span>.",
    "pack_more_images_trial_button": "GO PRO",
    "pack_more_images_pro": "<span>Here are your «{{num}}» more avatars</span><br> thanks to your <span>premium</span> subscription.",

    "error__default_message": "Something went wrong. Try again.",
    "error__network_message": "Please check your network connection.",
    "error__overload": "We are terrible sorry, but our servers experience too much of a load currently due to skyrocketing app downloads. Please, come back a little later. We are working hard to fix everything ASAP.",

    "create_page__duplicate_photos": "It looks like you’ve selected the same photo several times. Please choose <span>unique photos only</span>. AI needs at least <span>{{n}} unique photos</span> to start creating avatars, but with <span>{{m}} photos</span> the results can be even <span>better</span>.",

    "tooncoin_connect__empty_balance": "It seems like there are no coins in this wallet",
    "tooncoin_connect__try_another": "If you have another address please switch to it.",
    "tooncoin_connect__limit_reached_1": "Sorry, it looks like you have already created your free pack.",
    "tooncoin_connect__limit_reached_10": "Sorry, it looks like you are out of avatar packs. Please, stay tuned for more options.",
    "tooncoin_connect__close_button": "Close",
    "tooncoin_connect__buy_tooncoin_button": "Buy ToonCoin",
    "tooncoin_connect__use_another_wallet": "Use another wallet",
    "tooncoin_connect__connect_text": "This otherwise paid feature has been made free exclusively for crypto community.<br /><br />\n" +
      "Two free avatar pack is available to any user with non-empty Ethereum wallet.<br /><br />\n" +
      "Please connect with your wallet to continue.",
    "tooncoin_connect__connect_latest_button": "Continue as<br /><span>{{address}}</span>",
    "tooncoin_connect__connect_button": "Connect",
    "tooncoin_connect__connect_new_button": "Connect new",
    "tooncoin_connect__connect_notnow_button": "Not now",
    "tooncoin_connect__sign_text": "We need to make sure it’s you. Please sign the transaction with your wallet to create a pack.",
    "tooncoin_connect__sign_button": "Sign",
    "tooncoin_connect__open_app_to_sign": "Open your wallet to sign message",

    "month_short_0": "Jan",
    "month_short_1": "Feb",
    "month_short_2": "Mar",
    "month_short_3": "Apr",
    "month_short_4": "May",
    "month_short_5": "Jun",
    "month_short_6": "Jul",
    "month_short_7": "Aug",
    "month_short_8": "Sep",
    "month_short_9": "Oct",
    "month_short_10": "Nov",
    "month_short_11": "Dec",

    "privacy_policy": "Privacy Policy",
    "terms": "Terms of Use",
    "restore": "Restore",

    // ------

    "try_another_photo": "Change photo",
    "get_the_app_button": "Get the app",

    "store_app": "app store",
    "store_google": "google play",

    "error__error": "Error",
    "internal_error": "An error occurred...",
    "error__api_code_1": "Internal error",
    "error__api_code_2": "Incorrect params",
    "error__api_code_3": "Processing failure",
    "error__api_code_401": "Unauthorized request",
    "error__api_code_404": "Not found",
    "error__api_code_410": "The requested content is deleted",
    "error__api_code_415": "Sorry, this file format is not supported. Please upload an image file (JPEG or PNG).",
};

